<template>
  <div @click="closePopUp()" class="fixed w-full h-full transparent-background blurry-background"></div>
  <div class="fixed w-8/12 bg-white left-1/4 top-16 p-5">
    <div v-if="!showConfirmation">
      <div class="flex justify-between items-center">
        <h5 class="text-xl font-semibold">Buy Players</h5>
        <a @click="changeShowConfirmation(-2)" class="text-2xl font-bold" href="#">&times;</a>
      </div>
      <hr class="mt-2" />
      <div class="md:flex md:justify-around">
        <div class="mt-4">
          <div>
            <h1 class="font-semibold">Promotion</h1>
            <div class="md:flex justify-between">
              <div class="p-2 m-1 flex justify-between md:block bg-brightGray text-darkBlue">
                <div class="flex text-darkBlue">
                  <img src="@/assets/icons/basic-plan-icon.svg" alt="" />
                  <div class="pl-2">
                    <p class="font-bold">Basic</p>
                    <p class="text-gray-500 text-sm">01-24</p>
                  </div>
                </div>
                <div class="md:mt-7">
                  <div>
                    <p class="font-bold">$60</p>
                    <p class="text-gray-500 text-sm">per seat</p>
                  </div>
                </div>
              </div>
              <div class="p-2 m-1 flex justify-between md:block bg-brightGray text-darkBlue flex-1">
                <div class="flex text-darkBlue">
                  <img src="@/assets/icons/medium-plan-icon.svg" alt="" />
                  <div class="pl-2">
                    <p class="font-bold">Medium</p>
                    <p class="text-gray-500 text-sm">25-49</p>
                  </div>
                </div>
                <div>
                  <p class="md:mt-7 font-bold">$55</p>
                  <div class="md:flex">
                    <p class="text-gray-500 text-sm">per seat</p>
                    <p class="bg-green-600 rounded-xl w-max px-2 md:ml-3 text-sm text-white">
                      -8%
                    </p>
                  </div>
                </div>
              </div>
              <div class="p-2 m-1 flex justify-between md:block bg-brightGray text-darkBlue flex-1">
                <div class="flex text-darkBlue">
                  <img src="@/assets/icons/pro-plan-icon.svg" alt="" />
                  <div class="pl-2">
                    <p class="font-bold">Pro</p>
                    <p class="text-gray-500 text-sm">50+</p>
                  </div>
                </div>
                <div>
                  <p class="md:mt-7 font-bold">$50</p>
                  <div class="md:flex">
                    <p class="text-gray-500 text-sm">per seat</p>
                    <p class="bg-green-600 rounded-xl w-max px-2 md:ml-3 text-sm text-white">
                      -16%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="font-semibold mt-4">Number of players</p>
          <div class="flex items-center border p-3 mt-2">
            <p class="w-1/2">${{ basePrice }} per player</p>
            <div class="flex justify-end items-center">
              <p class="border py-0.5 px-1" @click="disminuir()">
                <em class="fa-solid fa-minus"></em>
              </p>
              <input class="w-1/6 text-center border p-0.5" type="number" v-model="players" min="1" />

              <p class="border py-0.5 px-1" @click="aumentar()">
                <em class="fa-solid fa-plus"></em>
              </p>
            </div>
          </div>
          <form id="stripe" @submit.prevent="handleSubmit" class="mt-5">
            <h5 class="font-semibold">Payment Details</h5>
            <div class="mt-1 p-2">
              <input class="mt-1 block w-full p-2 bg-grey-steps" type="text" placeholder="Name on card" />
            </div>
            <div class="px-2">
              <div class="my-2 border p-4" ref="card"></div>
            </div>
            <p class="text-danger">{{ stripeValidationError }}</p>
            <p class="text-danger" v-if="showCardError">
              There is a problem with your card, please try again!
            </p>
          </form>
        </div>
        <div class="md:w-6/12 mt-4">
          <p class="font-semibold">Discount Code</p>
          <div class="flex justify-between mt-2">
            <input style="background-color: #ecf0f5" v-model="promotionCode" id="promotion-code" class="w-6/12 p-2"
              type="text" placeholder="code" required />
            <button id="" class="w-2/5 text-white p-1" style="background-color: #1d2e48"
              v-on:click="validatePromotionCode" :disabled="disablePromotionCodeButton">
              Apply
            </button>
          </div>
          <h6 v-if="promotionCode === null" class="text-red-600 mt-1">
            Promotion code is not valid or active!
          </h6>

          <div class="flex justify-between mt-5 p-3 bg-grey-steps">
            <p class="font-semibold">Product</p>
            <p>Units</p>
            <p>Amount</p>
          </div>

          <div class="flex justify-between p-3">
            <p>Players</p>
            <p>{{ players }}</p>
            <p>${{ totalSubscription }}</p>
          </div>

          <div class="m-auto line"></div>

          <div class="flex justify-between p-3">
            <p>Discount ({{ percentageDiscount }}%)</p>
            <p>-${{ totalDiscount }}</p>
          </div>

          <div class="m-auto line"></div>

          <div class="flex justify-between mt-3 font-semibold text-2xl p-3">
            <p class="">Total</p>
            <h3>${{ totalPrice }}</h3>
          </div>

          <button form="stripe" class="w-full mt-4 text-color font-semibold bg-mindaro p-2" type="submit">
            <em v-if="proccessingPayment" class="fa fa-spinner fa-spin"></em>
            Pay
          </button>
        </div>
      </div>
      <p v-if="showError" class="text-center text-red-600">
        An error has ocurred, please try again!
      </p>
    </div>
    <div class="md:w-1/2 m-auto" v-if="showConfirmation">
      <div class="flex justify-between">
        <h5 class="text-base md:text-xl font-semibold">
          PLAYERS HAVE BEEN SUCCESSFULLY CREATED
        </h5>
        <a @click="changeShowConfirmation(-2)" class="text-2xl font-bold" href="#">&times;</a>
      </div>
      <em class="block mx-auto mt-5 mb-14 text-9xl fa-solid fa-circle-check check-icon"></em>
      <p class="mb-5 text-center">Players have been successfully added.</p>
    </div>
  </div>
</template>

<script>
import {
  getPromotionCodes,
  buySeats,
  getProductInfo,
} from "@/services/stripe/stripe.js";

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
let card = undefined;

export default {
  data() {
    return {
      players: 1,
      priceId: "",
      promotionCodesList: {},
      promotionCode: "",
      promotionCodeId: "",
      nameOnCard: "",
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationError: "",
      totalSubscription: 60,
      totalDiscount: 0,
      percentageDiscount: 0,
      totalPrice: 60,
      showCardError: false,
      showError: false,
      proccessingPayment: false,
      disablePromotionCodeButton: false,
      code: "",
      location: "",
      showConfirmation: false,
      basePrice: 60,
    };
  },
  watch: {
    players(newValue) {
      if (newValue < 1) {
        newValue = 1;
        this.players = 1;
      }

      if (newValue < 25) {
        this.basePrice = 60;
      } else if (newValue >= 25 && newValue < 50) {
        this.basePrice = 55;
      } else {
        this.basePrice = 50;
      }

      this.totalSubscription = this.basePrice * newValue;
      this.totalPrice = this.totalSubscription.toFixed(2);
      if (this.promotionCode) {
        this.validatePromotionCode();
      }
    },
    multiplePlayers(value) {
      if (value) {
        return (this.players = 2);
      }

      this.players = 1;
    },
  },
  methods: {
    closePopUp() {
      return;
    },
    aumentar() {
      this.players++;
    },
    disminuir() {
      if (this.players > 1) {
        this.players--;
      }
    },

    async handleSubmit() {
      if (this.players < 1) {
        return;
      }
      this.proccessingPayment = true;
      try {
        const result = await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            name: this.nameOnCard,
          },
        });
        await this.subscriptionRequest(result.paymentMethod.id);


        this.proccessingPayment = false;
        this.showConfirmation = true;
      } catch (e) {
        console.log(e);
        this.proccessingPayment = false;
        this.showError = true;
      }
    },

    async subscriptionRequest(paymentMethodId) {
      const response = await buySeats(
        paymentMethodId,
        process.env.VUE_APP_STRIPE_SEATS_SUBSCRIPTION_ID,
        this.players,
        this.promotionCodeId
      );

      if (!response.success) {
        this.showCardError = true;
        throw new Error("Error proccessing subscription");
      }
    },

    promotionCodes() {
      getPromotionCodes().then((response) => {
        if (response.success) {
          this.promotionCodesList = response.data;
        }
      });
    },
    validatePromotionCode() {
      if (this.promotionCode) {
        for (let obj of this.promotionCodesList.data) {
          if (obj.code === this.promotionCode) {
            this.promotionCodeId = obj.coupon.id;
            if (obj.active) {
              const priceOff =
                (this.totalSubscription * obj.coupon.percent_off) / 100;
              this.totalDiscount = priceOff;
              this.percentageDiscount = obj.coupon.percent_off;
              this.totalPrice -= priceOff;
              this.totalSubscription -= priceOff;
              this.disablePromotionCodeButton = true;
            }
          }
        }

        if (!this.promotionCodeId) {
          this.promotionCode = null;
        }
      }
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : "";
    },
    changeShowConfirmation(number) {
      this.$router.go(number);
    },
    async getProduct() {
      const product = await getProductInfo(this.code, this.location);

      this.priceId = product.data.product.default_price;
      this.productPrice = parseFloat(product.data.price) / 100;
    },
  },
  async created() {
    this.promotionCodes();
  },

  async mounted() {
    const elements = stripe.elements();
    const style = {
      base: {
        iconColor: "black",
        color: "black",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        ":-webkit-autofill": {
          color: "#F7F9FB",
        },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    };

    card = elements.create("card", { style });
    card.mount(this.$refs.card);
    card.on("change", this.setValidationError);

  },
};
</script>

<style scoped>
.blurry-background {
  filter: blur(5px);
}

.transparent-background {
  background: rgba(0, 0, 0, 0.5);
}

.check-icon {
  height: 87px;
  width: 87px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
